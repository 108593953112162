.card-animate {
  cursor: pointer;
}

.signatureCanvas {
  height: 100%;
  width: 100%;
}

.date-input {
  position: relative;
  min-width: 150px;
  /* height: 20px; */
  color: white;
}

.date-input:before {
  position: absolute;
  top: 10px;
  left: 3px;
  content: attr(data-date);
  display: inline-block;
  color: black;
}

.date-input::-webkit-datetime-edit,
.date-input::-webkit-inner-spin-button,
.date-input::-webkit-clear-button {
  display: none;
}

.date-input::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 10px;
  right: 0;
  color: black;
  opacity: 1;
}

.react-date-picker__wrapper {
  border: none !important;
}
